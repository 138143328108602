
<template>
    <div class="contenter">
        <h1>产品用户使用协议</h1>
        <p>尊敬的用户：</p>
        <p style="text-indent:30px;">在您使用北京搜知数据科技有限公司（以下简称：我公司）所有相关产品之前，请您仔细阅读本协议的全部内容。如果您不同意本协议的任意内容，或者无法准确理解我公司的解释，请不要进行后续操作。一旦您选择“同意”并进行后续操作，即表示您已详细阅读、完全理解并同意接受本协议之所有内容。如您有任何问题可以随时联系我们。届时您不得以任何未完整阅读或不能理解本协议内容或者未获得我公司对您的解答等不正当理由，主张本协议无效、欺诈、重大误解或撤销本协议内容。</p>
        <ul class="rules-warp">
            <li class="item">
                <h3 class="head">1、产品使用规则</h3>
                <ul class="rules-list">
                    <li class="rules-item">
                        <h3 class="item-head">1.1用户应遵守以下法律及法规</h3>
                        <p class="rules">（1）用户应遵守中华人民共和国有关法律、法规，承担一切因用户行为直接或间接引起的法律责任。在任何情况下，我公司认为用户的行为可能违反国家法律、法规，可以不经事先通知，随时终止向该用户提供服务；</p>
                        <p class="rules">（2）用户如不在国内使用，还应特别注意遵守所在国家和地区有关的法律和法规。</p>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">1.2关于用户名的注册和管理</h3>
                        <p class="rules">（1）请勿以党和国家领导人或其他名人的姓名、字、号、艺名、笔名、头衔等注册和使用昵称；</p>
                        <p class="rules">（2）请勿以国家组织机构、国际组织或其他组织机构的名称、简称等注册和使用昵称（如确为该机构，需要提交相关证据并通过审核方可允许使用）；</p>
                        <p class="rules">（3）请勿注册和使用不文明、不健康及有违社会公序良俗或违背社会主义核心价值观的昵称；</p>
                        <p class="rules">（4）请勿注册和使用容易产生歧义、引人误解或带有各种奇形怪状符号的昵称；</p>
                        <p class="rules">（5）其他违背法律、法规禁止性规定或导致社会不良影响的昵称。</p>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">1.3关于身份认证服务</h3>
                        <p class="rules">（1）身份认证服务是由我公司向正式机构用户提供的一项个人实名身份认证服务。您需要先注册个人账号，并对您的账号进行身份认证。我们会在获得您同意或您主动提供的情况下收集您的实名身份信息。</p>
                        <p class="rules">（2）在您进行身份认证时，应提供有关您本人真实、合法、准确、有效的身份信息及其他相关信息，且不得冒用他人身份资料进行身份认证。否则本公司有权注销您的个人账号，并向有关机构报告。</p>
                        <p class="rules">（3）您理解并同意，一旦您的个人账号完成了身份认证，相应的身份信息和认证结果将不能自行修改或取消。如果您的身份认证信息在完成认证后发生了变更，您应向本公司提供相应有权部门出具的凭证，由本公司协助您变更您用户账号的对应身份认证信息。</p>
                        <p class="rules">（4）经审核通过的身份认证申请将获得认证账号专属的产品权限，本公司有权根据产品线的不同，设置不同的认证账号专属权限。</p>
                        <p class="rules">（5）在您提交认证申请并审核通过后，您需要妥善管理个人认证账号，不得向他人出借或与他人共享。同时，也须对通过此认证账号下载的数据进行妥善管理并只能用于个人研究目的，不得公开、泄露、转售或用于商业牟利。一旦本公司发现此认证账号下非法下载、传播或泄露数据资源，本公司将追究您的法律责任。</p>
                        <p class="rules">（6）本身份认证服务目前仅适用于中华人民共和国大陆地区持有效身份证明的正式机构下的个人用户，暂不向中国台湾、中国香港、中国澳门及海外地区的用户提供认证服务。</p>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">1.4禁止性行为</h3>
                        <p class="rules">用户在我公司平台上发布信息时，必须遵守国家有关的法律法规，并承担一切因自己发布不当导致的法律后果。用户在我公司平台上所发布的信息，不得含有以下内容：</p>
                        <p class="rules">（1）违反法律、法规强制性规定或含有法律、行政法规禁止的其他内容的；</p>
                        <p class="rules">（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
                        <p class="rules">（3）损害国家荣誉和利益的，攻击党和政府及其领导人的；</p>
                        <p class="rules">（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
                        <p class="rules">（5）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的，以非法民间组织名义活动的；</p>
                        <p class="rules">（7）散布谣言或不实消息，扰乱社会秩序，破坏社会稳定的；</p>
                        <p class="rules">（8）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
                        <p class="rules">（9）违背中华民族传统美德、社会公德、伦理道德、以及社会主义精神文明的；</p>
                        <p class="rules">（10）宣扬种族歧视，破坏国家、民族、地区团结的言论和消息的；</p>
                        <p class="rules">（11）侵犯他人肖像权、姓名权、名誉权、隐私权或其他人身权利的；</p>
                        <p class="rules">（12）恶意重复、大量发布各种信息的；</p>
                        <p class="rules">（13）未经我公司同意，张贴任何形式广告的；</p>
                        <p class="rules">（14）利用本平台进行故意制作、传播计算机病毒等破坏性程序；</p>
                        <p class="rules">（15）发布信息时，任何人不得以任何原因对任何一位用户或公民进行人身攻击、侮辱、谩骂、诋毁、中伤、恐吓等。不得侵害他人合法权益；</p>
                        <p class="rules">（16）进行任何破坏或试图破坏网络安全的行为（包括但不限于钓鱼，黑客，网络诈骗，网站或空间中含有或涉嫌散播：病毒、木马、恶意代码，及通过虚拟服务器对其他网站、服务器进行涉嫌攻击行为如扫描、嗅探、ARP欺骗、DDOS等）；</p>
                        <p class="rules">（17）进行任何改变或试图改变我公司提供的系统配置或破坏系统安全及网络安全的行为；</p>
                        <p class="rules">（18）修改、翻译、改编、出租、转许可、在信息网络上传播或转让我公司提供的产品或服务，逆向工程、反编译或试图以其他方式发现我公司提供的产品的源代码；</p>
                        <p class="rules">（19）转载或引用我公司所有相关产品的内容时对产品的内容原意进行曲解、修改或破坏或利用产品的内容损害本公司或他人利益或非法牟利或利用非法手段侵害产品的知识产权或有其他任何可能危害公共秩序或计算机平台网络安全的违法行为；</p>
                        <p class="rules">（20）以任何将会违反国家、地方法律法规、行业惯例和社会公共道德，及影响、损害或可能影响、损害我公司利益的方式或目的使用产品。</p>
                        <p class="rules">如用户在我公司发布信息时，不遵守协议中的规定，本平台有权随时修改、删除用户发布的任何信息。并有权对任何违反协议的用户做出封禁ID；或暂时、永久禁止在本平台发布信息的处罚，同时保留依法追究当事人的一切法律责任的权利。我公司系统记录将作为用户违反法律的证据。</p>
                    </li>
                </ul>
            </li>
            <li class="item">
                <h3 class="head">2、版权声明</h3>
                <ul class="rules-list">
                    <li class="rules-item">
                        <h3 class="item-head">2.1 我公司所有相关产品的任何内容，包括但不限于数据、图表、商标、色彩、版式设计、文字、声音、音视频及任何其他信息，未经特殊说明，其版权均属我公司所有；</h3>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">2.2用户转载或引用我公司所有相关产品的内容时，必须是以新闻性或资料性公共免费信息为使用目的的合理、善意引用。转载或引用我公司所有相关产品版权所有之内容须注明"来源：北京搜知数据科技有限公司"字样。</h3>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">2.3若第三方网站以任何形式建立链接至我公司所有相关产品，必须先取得本公司同意。本公司有权决定是否核准建立此链接。凡从第三方网站建立任何链接至我公司所有相关产品，本公司不负责该链接的建立与设置。依此建立的链接，并不构成本公司与该第三方网站有任何形式的合作，亦不构成本公司对该第三方网站的认同。</h3>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">2.4对于违反国家有关法律法规，不尊重我公司相关声明的行为，本公司保留采取一切法律措施追究其法律责任的权利。</h3>
                    </li>
                </ul>
            </li>
            <li class="item">
                <h3 class="head">3、隐私保护</h3>
                <ul class="rules-list">
                    <li class="rules-item">
                        <h3 class="item-head">3.1您在认证时提交给本公司的认证资料，即不可撤销的授权由本公司保留。本公司承诺除法定或约定的事由外，不公开或编辑或透露您的认证资料及保存在本平台的非公开内容用于商业目的。但下列情况除外：</h3>
                        <p class="rules">（1） 您授权本公司可以公开透露的相关信息；</p>
                        <p class="rules">（2）本公司依法向国家机关提供的信息；</p>
                        <p class="rules">（3）第三方和本公司一起为用户提供服务时，该第三方向您提供服务所需的相关信息。</p>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">3.2 我们将采取合理的方式以保护您个人资料的安全。我们将使用必要的可以获得的安全技术和程序保护您的个人资料不被未经授权的访问、使用或泄漏。</h3>
                    </li>
                </ul>
            </li>
            <li class="item">
                <h3 class="head">4、用户行为规范</h3>
                <ul class="rules-list">
                    <li class="rules-item">
                        <h3 class="item-head">4.1 接受本协议全部内容和说明、条款、条件是您申请身份认证的先决条件。您声明并保证，您不得为任何非法或为本协议、条件及须知所禁止之目的进行身份认证申请。</h3>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">4.2 您应妥善保管好您的账户、密码及相关身份认证信息。若您未尽上述义务，或由于黑客攻击、计算机病毒侵入或发作、电信部门技术调整导致之影响、因政府管制而造成的暂时性关闭、第三方原因（包括不可抗力，例如国际出口的主干线路及国际出口电信提供商一方出现故障、火灾、水灾、雷击、地震、洪水、台风、龙卷风、火山爆发、瘟疫和传染病流行、罢工、战争或暴力行为或类似事件等）等非因本公司原因导致的账号密码遗失、账号被盗、个人资料泄露等情形，本公司不承担任何法律责任。</h3>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">4.3 您充分理解并完全同意，本公司有权依合理判断对违反有关法律法规或本协议规定的行为进行处理。对违法、违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等。用户应独自承担由此而产生的一切法律责任。因您违反本协议或相关服务条款，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担法律责任和后果。本公司因此遭受损失的，您也应当一并赔偿。</h3>
                    </li>
                </ul>
            </li>
            <li class="item">
                <h3 class="head">5、免责条款</h3>
                <ul class="rules-list">
                    <li class="rules-item">
                        <h3 class="item-head">5.1您在使用我公司所有相关产品并出现下述相关情况时，本公司无需承担任何责任：</h3>
                        <p class="rules">（1）由于您将个人认证账号密码告知他人或未保管好自己的密码或与他人共享个人账户或任何其他非本公司的过错，导致您的个人资料泄露。</p>
                        <p class="rules">（2） 由于不可抗力，例如网络故障、火灾、水灾、雷击、地震、洪水、台风、龙卷风、火山爆发、瘟疫和传染病流行、罢工、战争或暴力行为或类似事件等及其他非因本平台原因而造成的认证信息泄露、丢失、被盗用或被篡改等。</p>
                        <p class="rules">（3）任何我公司用户向本公司提供错误、不完整、不实信息等造成不能通过认证或遭受任何其他损失。</p>
                        <p class="rules">（4）任何由数据引起的直接或间接的损失，包括利润、收入或投资损失及其他可能的损失；</p>
                        <p class="rules">（5）任何不可预见性因素导致的损失；</p>
                        <p class="rules">（6） 非本公司或提供本服务的第三方所能控制的事件，包括互联网传输中断、延迟或数据错误，电讯、网络和电脑病毒，以及通讯设施故障引发的数据失真或不及时等。</p>
                        <p class="rules">我公司所有相关产品引用、摘录或转载来自第三方的内容时，并不表明这些内容代表本公司的观点或本公司应当承担责任，其目的只是供访问者交流与参考。</p>
                    </li>
                </ul>
            </li>
            <li class="item">
                <h3 class="head">6、修改和解释权</h3>
                <ul class="rules-list">
                    <li class="rules-item">
                        <h3 class="item-head">6.1 本公司有权单方随时修改或变更本协议内容，并通过我公司官网网站公告变更后的协议文本，而无需单独通知您。本协议进行任何修改或变更后，您如继续使用身份认证服务的，即代表您已完全阅读、理解并同意接受变更后的协议内容。您如果不同意变更后的协议内容，可停止使用我们所提供的服务。</h3>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">6.2 我们保留对本协议条款、产品和服务以及我们所提供的产品和服务的相关官方网站的最终解释权。</h3>
                    </li>
                </ul>
            </li>
            <li class="item">
                <h3 class="head">7、管辖与法律适用</h3>
                <ul class="rules-list">
                    <li class="rules-item">
                        <h3 class="item-head">7.1本协议签订地为中华人民共和国北京市海淀区。</h3>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">7.2本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律。</h3>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">7.3 因本协议发生的任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交至本协议签订地有管辖权的人民法院管辖。</h3>
                    </li>
                </ul>
            </li>
            <li class="item">
                <h3 class="head">8、其他</h3>
                <ul class="rules-list">
                    <li class="rules-item">
                        <h3 class="item-head">8.1本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</h3>
                    </li>
                    <li class="rules-item">
                        <h3 class="item-head">8.2 本协议条款无论因何种原因导致的部分无效，不影响其他条款的法律效力。其余条款仍有效，对各方具有法律约束力。</h3>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
// import {
//     urlSoz
// } from '../until/common.js';
export default {
    name:'Agreements',
    data(){
        return{
            urlData:'北京福卡斯特信息技术有限公司'
        }
    },
    mounted() {
        // if(document&&document.location.hostname===urlSoz){
        //     this.urlData = '北京搜知数据科技有限公司'
        // }else{
        //     this.urlData = '北京福卡斯特信息技术有限公司'
        // }
    }
}
</script>

<style scoped lang="scss">
    .contenter {
        width:1200px;
        margin:0 auto;
        font-size:16px;
        padding-bottom: 80px;
        .rules-warp{
            .item{
                .head{
                    text-indent:30px;
                    font-size: 16px;
                    font-weight: bolder;
                }
                .rules-list{
                    .rules-item{
                        .item-head{
                            margin:10px auto;
                            text-indent:30px;
                            font-size: 14px;
                        }
                        .weight{
                            font-weight: bolder;
                        }
                        .rules{
                            text-indent: 30px;
                        }
                    }
                }
            }
        }
        h1 {
            text-align: center;
            line-height:80px;
            font-size:24px;
            padding-top:20px;
        }
        h2 {
            margin:15px 0;
            line-height: 32px;
            font-weight: normal;
            font-size:16px;
        }
        p {
            line-height: 32px;
            margin:10px 0;
            font-size:14px;
            color: #333;
        }
        h2 span,p span {
            display: inline-block;
            width:30px;
            font-size:16px;
        }
    }
</style>